import React from 'react';
import type { WCmsWeddingRegistryPageView } from '@zola/svc-web-api-ts-client';
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';

import scrollViewTo from 'util/scrollViewTo';
import TitleImgDesc from 'components/publicWebsiteV2/common/TitleImgDesc';
import { useAppSelector } from 'reducers/useAppSelector';
import { getUserContext } from 'selectors/user/userSelectors';
import { trackEvent } from '@zola-helpers/client/dist/es/tracking/trackingHelper';
import getNonHomeCmsHeroImg from 'components/publicWebsiteV2/util/getNonHomeCmsHeroImg';

import ZolaRegistry from './ZolaRegistry';
import ExternalRegistry from './ExternalRegistry';
import {
  ExternalRegistrySection,
  Container,
  RegistryMenu,
  RegistryMenuItem,
  RegistryContent,
  EmptyStateMessage,
} from './Registry.styles';

type RegistryProps = {
  pageData?: WCmsWeddingRegistryPageView;
};

const Registry = ({ pageData }: RegistryProps): JSX.Element => {
  const {
    title,
    description,
    header_image_url,
    header_image_id,
    zola_registry,
    wedding_registries,
    images,
  } = pageData || {};
  const {
    state: {
      components: { cmsEntityComponentBodyFontValues, styleCmsEntityBodyFont },
      inPreview,
    },
  } = useWebsiteThemeContext();

  const userContext = useAppSelector(getUserContext);

  const cmsHeaderImgUrl = getNonHomeCmsHeroImg(images);

  // @ts-expect-error ts-migrate(2551) FIXME: Property 'public' does not exist on type 'WCmsZola...
  const showZolaRegistry = Boolean(zola_registry?.public || inPreview);
  const showExternalRegistries = (wedding_registries || []).length > 0;
  const showRegistryMenu = showZolaRegistry && showExternalRegistries;
  const isGuestViewing = userContext?.registry_ids?.[0] !== zola_registry?.registry_object_id;

  const handleExternalRegistryClick = (index: number) => {
    scrollViewTo({ id: `other-registry-${index}`, offset: 190 });
    if (isGuestViewing) {
      trackEvent('Public Wedding Website', {
        action: 'Registry Clicked',
        label: 'Other Registries',
      });
    }
  };

  const showEmptyStateMessage = !(showZolaRegistry || showExternalRegistries) && !description;

  const StyledEmptyStateMessage = styleCmsEntityBodyFont(EmptyStateMessage);

  return (
    <Container>
      <TitleImgDesc
        title={title}
        description={description}
        url={cmsHeaderImgUrl || header_image_url}
        alt={header_image_id}
      />
      <RegistryContent>
        {showRegistryMenu && (
          <RegistryMenu
            style={{
              fontFamily: cmsEntityComponentBodyFontValues.fontFamily,
              color: `#${cmsEntityComponentBodyFontValues.color}`,
              borderBottomColor: `#${cmsEntityComponentBodyFontValues.color}`,
              borderTopColor: `#${cmsEntityComponentBodyFontValues.color}`,
            }}
          >
            <RegistryMenuItem selected>Zola</RegistryMenuItem>
            {(wedding_registries || []).map((r, i) => (
              <RegistryMenuItem
                key={`registry-${r.name}`}
                onClick={() => handleExternalRegistryClick(i)}
              >
                {r.name}
              </RegistryMenuItem>
            ))}
          </RegistryMenu>
        )}
        {showZolaRegistry && (
          <ZolaRegistry isGuestViewing={isGuestViewing} zolaRegistry={zola_registry} />
        )}
        {showExternalRegistries && (
          <ExternalRegistrySection
            style={{ borderTopColor: `#${cmsEntityComponentBodyFontValues.color}` }}
          >
            {(wedding_registries || []).map((r, i) => (
              <ExternalRegistry
                name={r.name}
                description={r.description}
                url={r.url}
                key={r.id}
                id={`other-registry-${i}`}
              />
            ))}
          </ExternalRegistrySection>
        )}
        {showEmptyStateMessage && (
          <StyledEmptyStateMessage>
            We&apos;ll post our registry here when we&apos;re finished building it. Thank you in
            advance!
          </StyledEmptyStateMessage>
        )}
      </RegistryContent>
    </Container>
  );
};

export default Registry;
